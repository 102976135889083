import axios from "axios";
import {GET_COUNTRIES, EDIT_COUNTRY, GET_COUNTRY, DELETE_COUNTRY
    ,ADD_ROOM, DELETE_ROOM, GET_ROOM, EDIT_ROOM,
     GET_ROOMS, ADD_COUNTRY, GET_ALL_ROOMS} from './types'

     
//get countries
export const getCountries = () => dispatch => {

    axios.get(`${process.env.REACT_APP_URL}/countries`)
    .then(res => {
        dispatch({
            type: GET_COUNTRIES, 
            payload: res.data
        })
    })
}

//get country
export const getCountry = (id) => dispatch => {

    axios.get(`${process.env.REACT_APP_URL}/country/${id}`)
    .then(res => {
        dispatch({
            type: GET_COUNTRY, 
            payload: res.data
        })
    })
}

//get countries
export const editCountry = (id, body) => dispatch => {

    axios.post(`${process.env.REACT_APP_URL}/country/${id}`, body)
    .then(res => {
        dispatch({
            type: EDIT_COUNTRY, 
            payload: res.data
        })
    })

}



//get countries
export const addCountry = (body) => dispatch => {

    axios.post(`${process.env.REACT_APP_URL}/country`, body)
    .then(res => {
        dispatch({
            type: ADD_COUNTRY, 
            payload: res.data
        })
    })
}




//delete countries
export const deleteCountry = (id) => dispatch => {

    axios.delete(`${process.env.REACT_APP_URL}/country/${id}`)
    .then(res => {
        dispatch({
            type: DELETE_COUNTRY, 
            payload: res.data
        })
    })
}

//get room
export const addRoom = ( body) => dispatch => {

    axios.post(`${process.env.REACT_APP_URL}/room/`, body)
    .then(res => {
        dispatch({
            type: ADD_ROOM, 
            payload: res.data
        })
    })
}

//get room
export const getRoom = (id) => dispatch => {

    axios.get(`${process.env.REACT_APP_URL}/room/${id}`)
    .then(res => {
        dispatch({
            type: GET_ROOM, 
            payload: res.data
        })
    })
}

//get room
export const deleteRoom = (id) => dispatch => {

    axios.delete(`${process.env.REACT_APP_URL}/room/${id}`)
    .then(res => {
        dispatch({
            type: DELETE_ROOM, 
            payload: res.data
        })
    })
}


//edit room
export const editRoom = (id, body) => dispatch => {

    axios.put(`${process.env.REACT_APP_URL}/room/${id}`, body)
    .then(res => {
        dispatch({
            type: EDIT_ROOM, 
            payload: res.data
        })
        
    })
    
}

//get rooms
export const getRooms = (country) => dispatch => {

    axios.get(`${process.env.REACT_APP_URL}/rooms/${country}`)
    .then(res => {
        dispatch({
            type: GET_ROOMS, 
            payload: res.data
        })
    })
}


//get rooms
export const getAllRooms = () => dispatch => {

    axios.get(`${process.env.REACT_APP_URL}/rooms`)
    .then(res => {
        dispatch({
            type: GET_ALL_ROOMS, 
            payload: res.data
        })
    })
}


